import { createChart, ColorType } from "lightweight-charts";
import { useEffect, useRef, useState,useMemo  } from "react";
import DateSelector from '../DateSelector';
import ChartLegend from "../ChartLegend";
// import { fetchPulseGymData } from "../utils/dataUtils";
// each object to predict(full dataset) -> model object + model episodes
import simul_data1_simple from '../../pulsegym_dev_data/dev_simul/simul_data1_simple.json';
import simul_data2_simple from '../../pulsegym_dev_data/dev_simul/simul_data2_simple.json';
import simul_data3_simple from '../../pulsegym_dev_data/dev_simul/simul_data3_simple.json';
import simul_data4_simple from '../../pulsegym_dev_data/dev_simul/simul_data4_simple.json';
import defaultGOPData from '../../pulsegym_dev_data/dev_structures/202308f1fe3496552b_gop/202308f1fe3496552b_gop.json'

import simul_data1_simple_forecast_future_AR1 from '../../pulsegym_dev_data/dev_forecast/simul_data1_simple_forecast_future_AR1.json'
import simul_data1_simple_forecast_train_AR1 from '../../pulsegym_dev_data/dev_forecast/simul_data1_simple_forecast_train_AR1.json'

// import { extractAndFlattenMetrics } from '../utils/extractAndFlattenMetrics'; // Adjust the import path as needed



export const PriceChartComponent = (props) => {

    const {

        colors: {
            backgroundColor = "white",
            lineColor = "#2962FF",
            textColor = "black",
            areaTopColor = "#2962FF",
            areaBottomColor = "rgba(91, 98, 255, 0.28)",
        } = {},
    } = props;

    const chartContainerRef = useRef();
    const [chartData, setChartData] = useState([]);
    const [startDate, setStartDate] = useState(new Date('2005-01-01'));
    const [endDate, setEndDate] = useState(new Date('2015-12-14'));
    // const [forecast, setForecast] = useState(null);
    const [error, setError] = useState(null);
    const [showPrediction, setShowPrediction] = useState(false);


    // const [gopData, setGopData] = useState(null);

    // // user choose which data id to use
    const [gopId] = useState("202308f1fe3496552b_gop");

    const [gmoId] = useState("202308a71819e1c67f_gmo");
    // const dataPath = "../pulsegym_dev_data/dev_structures"
    const [gopData, setGopData] = useState(defaultGOPData.entire_dataset);

    // State variables to store gmo data and gme episodes data


      // useMemo to memoize datasetOptions
    const datasetOptions = useMemo(() => [
        { id: 'dataset1', label: 'Dataset 1', value: simul_data1_simple },
        { id: 'dataset2', label: 'Dataset 2', value: simul_data2_simple },
        { id: 'dataset3', label: 'Dataset 3', value: simul_data3_simple },
        { id: 'dataset4', label: 'Dataset 4', value: simul_data4_simple },
        { id: gopId, label: gopId, value: gopData }
        // ... other dataset options
    ], [gopId, gopData]);

    const [selectedDatasetId, setSelectedDatasetId] = useState(datasetOptions[4].id);

    // dynamically import model object and data episodes data


    useEffect(() => {
        // Define the path for gmo.json
        const gmoPath = `pulsegym_dev_data/dev_structures/${gopId}/${gmoId}/${gmoId}.json`;

        // Dynamically import gmo.json
        import(`../../${gmoPath}`)
            .then((gmoModule) => {
                // Store the gmo data in the state variable
              

                // Extract the gm_episodes_store IDs from gmo.json
                const gmEpisodesStore = gmoModule.default.gm_episodes_store;

                // Define an array of data paths based on the extracted IDs
                const dataPaths = Object.values(gmEpisodesStore).flat().map((episodeId) => {
                    return `pulsegym_dev_data/dev_structures/${gopId}/${gmoId}/${episodeId}.json`;
                });


                // Use Promise.all to dynamically import gme episodes data
                return Promise.all(
                    dataPaths.map(async (path) => {
                        try {
                            const module = await import(`../../${path}`);
                            return module.default;
                        } catch (error) {
                            console.error(`Error loading data from ${path}:`, error);
                            return null; // Handle the error gracefully
                        }
                    })
                );
            })
            .then((dataArray) => {
                            // Use the imported data as needed
            })
            .catch((error) => {
                // Handle any errors during data loading
                console.error("Error happened while loading data:", error);
            });
    }, [startDate, endDate, selectedDatasetId, datasetOptions,gmoId , gopId ]);


    // dynamically import the object to predict dataset according to gopId
    useEffect(() => {
        const dataPaths = [
            `pulsegym_dev_data/dev_structures/${gopId}/${gopId}.json`,
            'pulsegym_dev_data/dev_forecast/simul_data1_simple_forecast_future_AR1.json',
            'pulsegym_dev_data/dev_forecast/simul_data1_simple_forecast_train_AR1.json'
        ];

        const dataPromises = dataPaths.map(async (path) => {
            try {
                const module = await import(`../../${path}`);
                return module.default;
            } catch (error) {
                console.error(`Error loading data from ${path}:`, error);
                return null; // Handle the error gracefully
            }
        });

        // You can use Promise.all to wait for all data to be loaded
        Promise.all(dataPromises)
            .then((dataArray) => {
                // dataArray will contain the imported data
                const [
                    dataIneed
                ] = dataArray;

                setGopData(dataIneed.entire_dataset);

                // Use the imported data as needed
            })
            .catch((error) => {
                // Handle any errors during data loading
                console.error("Error happens loading data:", error);
            });

    }, [gopId,gmoId ]);


    useEffect(() => {
        if (!startDate || !endDate) {
            setError('Please select a start date and an end date');
            return;
        }
        setError(null); // Clear error message when selections are made



        // Filter and format the selected dataset based on the selected date range
        const selectedDatasetObject = datasetOptions.find(option => option.id === selectedDatasetId);
        const datasetData = selectedDatasetObject.value;
    

        const filteredData = datasetData.filter(item => {
            // Remove the extra space at the end and extract the date part (yyyy-mm-dd)
            const dateString = item.date.trim().split(' ')[0];
        
            const itemDate = new Date(dateString);
            return itemDate >= startDate && itemDate <= endDate;
        });


        const formattedData = filteredData.map(item => ({
            time: item.date.split(' ')[0],
            // value: parseFloat(item.price_sim)
            value: parseFloat(item.Price_Simulation)
        }));

        setChartData(formattedData);
    }, [startDate, endDate, selectedDatasetId,datasetOptions]); // This effect will run whenever startDate or endDate changes


    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (!startDate || !endDate) {
    //             setError('Please select a commodity and a forecast');
    //             return;
    //         }
    //         setError(null); // clear error message when selections are made
    //         axios.get("https://dmp81l7ts5.execute-api.us-east-1.amazonaws.com/DEV", {
    //             params: {
    //                 start_date: format(startDate, 'yyyy-MM-dd'),
    //                 end_date: format(endDate, 'yyyy-MM-dd'),
    //                 commodity: selectedCommodity
    //                 //  commodity: selectedCommodity.value,
    //                 //  forecast: forecast.value,
    //                 //   apiKey: API_KEY,
    //             },
    //         })
    //             .then((response) => {
    //                 const formattedData = response.data.map(item => ({
    //                     time: item.date.split('T')[0], // split the date at 'T' and take the first part
    //                     value: parseFloat(item.price_us)
    //                 }));
    //                 setChartData(formattedData);
    //                 // console.log(formattedData);
    //             }).catch(function (error) {
    //                 console.error('Error:', error);
    //             });

    //     };

    //     fetchData();
    // }, [selectedCommodity, startDate, endDate]);

    /*
      const fetchData = async () => {
        fetch('https://dmp81l7ts5.execute-api.us-east-1.amazonaws.com/DEV?start_date=2006-06-22&end_date=2023-01-22')
          .then(response => response.json())
          .then(data => {
            const transformedData = data.map(item => ({
              time: item.date.split('T')[0], // split the date at 'T' and take the first part
              value: parseFloat(item.price_us)
            }));
            setChartData(transformedData);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }, []);
      */

    useEffect(() => {
        const handleResize = () => {
            chart.applyOptions({ width: chartContainerRef.current.clientWidth });

        };

        const chart = createChart(chartContainerRef.current, {

            layout: {
                background: { type: ColorType.Solid, color: backgroundColor },
                textColor,
            },
            width: chartContainerRef.current.clientWidth,
            height: '350',
        });

        chart.timeScale().fitContent();

        const dailySeries = chart.addAreaSeries({
            lineColor,
            topColor: areaTopColor,
            bottomColor: areaBottomColor,
        });

        dailySeries.setData(chartData);

        // Conditionally add the prediction data series based on showPrediction prop
        if (showPrediction) {
            const predictionSeries = chart.addLineSeries({
                color: "green", // Set the color for the prediction series
            });

            const predictionTrainSeries = chart.addLineSeries({
                color: "orange", // Set the color for the prediction train series
            });

            // Replace this with your actual prediction data
            const formattedPredictionData = simul_data1_simple_forecast_future_AR1.map(item => ({
                time: item.date.split('T')[0], // Split the date at 'T' and take the first part
                value: parseFloat(item.price_forecast)
            }));
            // console.log(formattedPredictionData);

            predictionSeries.setData(formattedPredictionData);

            const formattedPredictionTrainData = simul_data1_simple_forecast_train_AR1.map(item => ({
                time: item.date.split('T')[0], // Split the date at 'T' and take the first part
                value: parseFloat(item.forecasted_mean)
            }));
            console.log(formattedPredictionTrainData);

            predictionTrainSeries.setData(formattedPredictionTrainData);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };


    }, [chartData, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor, showPrediction]);

    return (

        <div id='graphandmetrics' >
            <h1 className="font-bold mb-4 text-center">Prediction Chart</h1>
            <div>
                {/* Use the imported data here */}
                {gopData && (
                    <div>
                        {/* {selectedDatasetId} */}

                        {/* <pre>{JSON.stringify(gopData, null, 2)}</pre> */}
                        {/* {<pre>{JSON.stringify(gmoData, null, 2)}</pre>} */}
                        {/* {<pre>{JSON.stringify(gmeEpisodesData, null, 2)}</pre>} */}
                    </div>
                )}
                {/* ... Render other imported data if needed */}
            </div>
            {/* <CommoditySelector selectedCommodity={selectedCommodity} setSelectedCommodity={setSelectedCommodity} /> */}
            {/* <Container maxWidth="sm" className="flex justify-between items-center mb-4"> */}
            <div className="flex items-center justify-start space-x-4 mb-4">
                <div className="flex flex-col ">
                    <label className="text-gray-500">Start Date</label>
                    <DateSelector selectedDate={startDate} setSelectedDate={setStartDate} />
                </div>
                <div className="flex flex-col">
                    <label className="text-gray-500">End Date</label>
                    <DateSelector selectedDate={endDate} setSelectedDate={setEndDate} />
                </div>
                <div className="flex-grow"></div>
                <div className="flex flex-col">
                    {/* Add a dropdown selector for dataset options */}
                    {/* Map through the dataset options array */}
                    <label className="text-gray-500">Selected Dataset</label>
                    <select
                        value={selectedDatasetId}
                        onChange={(event) => setSelectedDatasetId(event.target.value)}
                        className="w-full p-2 border rounded-md bg-white shadow-sm"
                        style={{ minWidth: '120px' }}
                    >
                        {datasetOptions.map(option => (
                            <option key={option.id} value={option.id}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>

                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded whitespace-nowrap"
                    onClick={() => setShowPrediction(!showPrediction)}
                >
                    {showPrediction ? "Hide Prediction" : "Show Prediction"}
                </button>
            </div>



            {/* </Container> */}

            {error && <p>{error}</p>}
            {/* <div>
                {startDate && <p>Selected date: {startDate.toString()}</p>}
                {selectedCommodity && <p>Selected option: {selectedCommodity.label}</p>}
            </div> */}
            <ChartLegend /> {/* Add the ChartLegend component */}
            <div
                ref={chartContainerRef}
            />
        </div >
    );
};