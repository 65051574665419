import React from 'react';
import { Link } from 'react-router-dom';
const pageLinks = [
    { id: 1, href: '/gym', text: 'Gym' },
    { id: 2, href: '/oracle', text: 'Oracle' },
    { id: 3, href: '/portfolio', text: 'Portfolio' },
    { id: 101, href: '/userprofile', text: 'My Profile' },
];

const PageLinks = ({ parentClass, closeMenu }) => {
    return (
        <ul className={`flex flex-col md:flex-row list-none m-0 p-0 ${parentClass}`}>
            {pageLinks.map((link) => {
                return (
                    <li  key={link.id} className={`mr-4 last:mr-0`}>
                    <Link   to={link.href} onClick={closeMenu} className="text-base hover:text-sky-500">
                      {link.text}
                    </Link>
                  </li>
                );
            })}
        </ul>
    );
};

export default PageLinks;

