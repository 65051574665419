import React, { useState } from 'react';
const Footer = () => {
    const [showModal, setShowModal] = useState(false);

    const handleModalToggle = () => {
        setShowModal(!showModal);
    };

    return (
        <div className=" bg-gray-100 px-3 py-10 z-50"> {/* Added relative here */}
            <div className="max-w-5xl mx-auto flex justify-center items-center"> {/* Centered container for the items */}
                <div className="flex justify-around items-start w-full"> {/* Full width container for equal spacing */}

                    <div className="text-left text-gray-600 font-bold ">
                        <div className="hover:text-sky-400 " >Careers</div>
                        <div className="hover:text-sky-400 mt-3"><a href="https://www.crunchbase.com/organization/future-edge" target="_blank" rel="noopener noreferrer">Press</a></div>
                        <div className="hover:text-sky-400 mt-3">Partners</div>
                    </div>
                    <div className="text-left text-gray-600  font-bold">
                        <div className="hover:text-sky-400">Privacy Policy</div>
                        <div onClick={handleModalToggle} className="cursor-pointer hover:text-sky-400 mt-3">Terms and Conditions</div>
                        <div className="hover:text-sky-400 mt-3"> Contact Us: <a href="mailto:support@ftredge.com" >support@ftredge.com</a></div>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-5 w-2/3">
                        <h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>
                        <p>Your terms and conditions content goes here.</p>
                        <button onClick={handleModalToggle} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">Close</button>
                    </div>
                </div>
            )}
            <div className="mt-16 border-t border-gray-200 text-gray-400   text-left "> {/* added mt-4 for a bit of margin */}
                © {new Date().getFullYear()} Future Edge Group. All Rights Reserved.
            </div>
            <div className=" text-gray-400  pt-4 justify-left text-left"> 

               Charts, Analytics and Underlying data provided in the platform are provided for information
                purposes only and should not be taken as an offer to buy or sell, or a
                solicitation to buy or sell, gold, commodities, stocks or any other
                financial products. Whilst every effort has been made to ensure the
                accuracy of all information used, we cannot guarantee
                such accuracy; nor do we accept responsibility for any losses or damages
                arising directly, or indirectly, from the use of this information.
                Past performance is not indicative of future results. Future Edge Group.
              
            </div>


        </div>
    );

}
export default Footer;