import React from "react";
import { Routes, Route } from 'react-router-dom';
// import { useUserContext } from './UserContext';
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ApprovalPage from "./pages/ApprovalPage";
import Gym from "./pages/Gym";
import NotFoundPage from './pages/NotFoundPage'; // Import the component
import UserProfile from "./components/UserProfile";
import RoutesForApprovedUsers from "./components/RoutesLogic/RoutesForApprovedUsers";
import RoutesForPublicUsers from "./components/RoutesLogic/RoutesForPublicUsers";



const AppRoutes = () => {
  // const { currentUser, isUserDataLoaded } = useUserContext();

  return (
    <Routes>
      <Route path='/' element={<RoutesForPublicUsers><SignIn /></RoutesForPublicUsers>}  />
      <Route path='/signin' element={<RoutesForPublicUsers><SignIn /></RoutesForPublicUsers>} />
      <Route path='/signup' element={<RoutesForPublicUsers><SignUp /></RoutesForPublicUsers>} />

      <Route path='/approval' element={<ApprovalPage />} />

      <Route path="/gym" element={<RoutesForApprovedUsers><Gym /></RoutesForApprovedUsers>} />
      <Route path="/userprofile" element={<RoutesForApprovedUsers><UserProfile /></RoutesForApprovedUsers>} />
      <Route path="*" element={<NotFoundPage />} />
      
      {/* <Route path='*' element={currentUser && isUserDataLoaded ? <Gym /> : <LandingPage />} /> */}
    </Routes>
  );
};

export default AppRoutes;