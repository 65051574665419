import React, { useState } from 'react';

const FieldEditable = ({ fieldName, db_name, value, onSave }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const handleSave = () => {
    onSave(db_name, editedValue);
    setEditMode(false);
  };

  return (
    <div className="flex items-center p-2 border-b border-gray-200">
      <strong className="w-1/3 text-gray-700">{fieldName}:</strong>
      {editMode ? (
        <div className="flex flex-grow items-center">
          <input 
            type="text" 
            value={editedValue} 
            onChange={(e) => setEditedValue(e.target.value)} 
            className="flex-grow border px-2 py-1 mr-2"
          />
          <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded">Save</button>
        </div>
      ) : (
        <div className="flex flex-grow items-center">
          <span className="flex-grow">{editedValue || 'Not set'}</span>
          <button onClick={() => setEditMode(true)} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-4 rounded">Edit</button>
        </div>
      )}
    </div>
  );
};

export default FieldEditable;