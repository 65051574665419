
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom'; // Import Navigate
import Navbar from "./components/Navbar";
import Announcement from "./components/Announcement";

import Footer from "./components/Footer";
import { UserContextProvider } from './UserContext';
import Loader from "./components/Loader";
import AppRoutes from './AppRoutes';


export default function App() {
  const [scrolled, setScrolled] = useState(false);
  const [bgImageLoaded, setBgImageLoaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    // Get the height of the announcement and set it as the top padding of the Navbar


    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  //Set lastLocation in sessionStorage whenever the location changes
  useEffect(() => {
    sessionStorage.setItem('lastLocation', location.pathname);
    console.log("Saving location", location.pathname);
  }, [location]);

  useEffect(() => {
    const lastLocation = sessionStorage.getItem('lastLocation');
    window.scrollTo(0, 0);
    if (lastLocation && lastLocation !== location.pathname) {
      console.log("Navigating to location", location);
      navigate(lastLocation);
    }
  }, [navigate, location]);

  useEffect(() => {
    // Check for loaded background image here, to ensure the Navbar doesn't load on its own the first time
    const img = new Image();
    img.src = './assets/images/main_background_shaded_highres.jpg';
    img.onload = img.onerror = () => setBgImageLoaded(true);
  }, []);


  if (!bgImageLoaded) {
    return (
      <Loader />
    );
  }

  

  return (
    <UserContextProvider>
      <>
        <div className="flex flex-col min-h-screen">
          <div className="fixed top-0 w-full z-50 bg-transparent">
            <Announcement />
            <Navbar isScrolled={scrolled} />
          </div>
          <AppRoutes style={{ paddingTop: '100px' }} />
          <Footer />
        </div>
      </>
    </UserContextProvider>

  );
};
