// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app)
//Check if we're running in a local environment

// if (process.env.REACT_APP_USE_FIREBASE_EMULATOR === "true") {
//     console.log("Using Firebase Emulator because of Env Variable");
//     connectAuthEmulator(auth, "http://localhost:9099");
//     connectFirestoreEmulator(firestore, "http://localhost:8080");
// }
if (window.location.hostname === "localhost") {
    console.log("Using Firebase Emulator because of hostname==localhost");
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(firestore, '127.0.0.1',8080);
    
}

export { auth };
export { firestore };