import { getAuth } from 'firebase/auth';

let API_BASE_URL;

if (process.env.REACT_APP_ENV === 'production') {
    API_BASE_URL = 'https://ftredge.com';
} else if (process.env.REACT_APP_ENV === 'staging') {
    API_BASE_URL = 'https://staging-api.ftredge.com'; // Google Global Load Balancer integrated with Cloud Run
    // API_BASE_URL = 'https://ipulse-core-app-staging-37jxapyarq-uc.a.run.app'; // Google Cloud Run
    // API_BASE_URL= 'https://apigtwstaging-5o9ih5u0.uc.gateway.dev' // Google Cloud API Gateway -> not working causing CORS issue before hitting Cloud Run
} else {
    API_BASE_URL = 'http://localhost:8000';
}

console.log('API_BASE_URL:', API_BASE_URL);
console.log(process.env);
// ##################   USER-PROFILES ####################
const USER_PROFILES_URL= `${API_BASE_URL}/api/v1/pulseroot/core/user/user-profiles/`;


async function getUserProfile(usrProfile_Uid) {
    console.log('getUserProfile', usrProfile_Uid);

    // Check if docUID is null or falsy and handle the error
    if (!usrProfile_Uid) {
        throw new Error("usrProfile_Uid is required and cannot be null or empty.");
    }

    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();

        const response = await fetch(`${USER_PROFILES_URL}${usrProfile_Uid}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Error fetching user profile: ${errorData.message || response.status}`);
        }
        return response.json();
    } catch (error) {
        if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
            console.error('Network error: Unable to reach the server.');
        } else {
            console.error('Error in getUserProfile:', error.message);
        }
        throw error;
    }
}

async function updateUserProfile(docUID, updatedData) {
    try {
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();

        const response = await fetch(`${USER_PROFILES_URL}${docUID}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedData)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Error updating profile: ${errorData.message || response.status}`);
        }

        return response.json();
    } catch (error) {
        // Log the error or handle it as needed
        console.error('updateUserProfile:', error.message);
        throw error; // Re-throwing the error for the caller to handle
    }
}
export { getUserProfile, updateUserProfile, getUserStatus };


// #######################  USER-STATUSES ###########################

async function getUserStatus(docUID) {
    try {
        
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();

        const response = await fetch(`${API_BASE_URL}/api/v1/core/users/user-statuses/${docUID}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Error fetching user status: ${errorData.message || response.status}`);
        }
        return response.json();
    } catch (error) {
        if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
            console.error('Network error: Unable to reach the server.');
        } else {
            console.error('Error in getUserStatus:', error.message);
        }
        throw error;
    }
}

