import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth } from './firebaseConfig';

import Loader from "./components/Loader";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup
} from "firebase/auth";

// import { auth, firestore } from './firebaseConfig/firebaseConfig'; // Was used when were relying on Firebase Collection data and not only Firebase Auth
// import { getDoc, doc } from "firebase/firestore"; // Was used when were relying on Firebase Collection data and not only Firebase Auth

const userContext = createContext();

export const useUserContext = () => useContext(userContext);

export const UserContextProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState(null);
    const [authCheckComplete, setAuthCheckComplete] = useState(false);
    const [userAuthInfo, setUserAuthInfo] = useState({
        email: null,
        username: null,
        emailVerified: false,
        hasApprovedAccess: false,
        userTypes: {
            isSuperadmin: false,
            isAdmin: false,
            isEmployee: false,
            isInternal: false,
            isContributor: false,
            isExpert: false,
            isCustomer: false
        }
    });

    // const [isUserDataLoading, setIsUserDataLoading] = useState(false); // Was used when were relying on Firebase Collection data and not only Firebase Auth
    // const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);// Was used when were relying on Firebase Collection data and not only Firebase Auth

    // Previously all were functions like "function singIn  (email, password) {..}"
    const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password);
    const signUp = (email, password) => createUserWithEmailAndPassword(auth, email, password);
    const logOut = () => signOut(auth);
    const googleSignIn = () => signInWithPopup(auth, new GoogleAuthProvider());

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setCurrentUser(user); // Set the current Firebase user
            if (user) {
                try {
                    await user.getIdToken(true);
                    const idTokenResult = await user.getIdTokenResult();

                      // Define updateUserTypes directly inside useEffect
                      const updateUserTypes = () => {
                        setUserAuthInfo(prevState => ({
                            ...prevState,
                            userTypes: {
                                isSuperAdmin: idTokenResult.claims.usertypes?.includes('superadmin'),
                                isAdmin: idTokenResult.claims.usertypes?.includes('admin'),
                                isEmployee: idTokenResult.claims.usertypes?.includes('employee'),
                                isContributor: idTokenResult.claims.usertypes?.includes('contributor'),
                                isExpert: idTokenResult.claims.usertypes?.includes('expert'),
                                isCustomer: idTokenResult.claims.usertypes?.includes('customer')
                            }
                        }));
                    };
                        // Set initial user auth info without userTypes
                    setUserAuthInfo({
                        email: user.email,
                        username: user.displayName || user.email.split('@')[0],
                        emailVerified: user.emailVerified,
                        hasApprovedAccess: idTokenResult.claims.approved,
                        // Initially set userTypes here if needed, or remove it
                    });
                    // Then update userTypes separately
                    updateUserTypes();
                } catch (error) {
                    console.error("Error processing user token:", error);
                    // Handle error (e.g., show error message, log out user)
                }
            } else {

                setUserAuthInfo({
                    email: null,
                    username: null,
                    emailVerified: false,
                    hasApprovedAccess: false,
                    userTypes: {
                        isSuperAdmin: false,
                        isAdmin: false,
                        isEmployee: false,
                        isContributor: false,
                        isInternal: false,
                        isExpert: false,
                        isCustomer: false
                    }
                });
            }
            setAuthCheckComplete(true);
        });

        return unsubscribe;
    }, [])


    // Was used when were relying on Firebase Collection data and not only Firebase Auth
    // const fetchUserData = async (user) => {
    //     try {
    //         console.log("User UID :", user.uid);
    //         const userInfoDoc = await getDoc(doc(firestore, userCollectonName, user.uid));

    //         if (userInfoDoc.exists()) {
    //             const userData = userInfoDoc.data();
    //             setUserApproved(userData.approved ? userData.approved : false);
    //             console.log("userApproved :", userData.approved);
    //             setUserUsername(userData.username ? userData.username : user.email);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching user data:", error);
    //     }finally{
    //         setIsUserDataLoading(false);
    //     }
    // };
    // useEffect(() => {
    //     setHasApprovedAccess(currentUser && userApproved===true);
    //     // setIsUserDataLoaded( authCheckComplete && currentUser && !isUserDataLoading);
    // }, [currentUser, userApproved]);

    const contextValue = {
        authCheckComplete,
        currentUser,
        ...userAuthInfo,
        signIn,
        signUp,
        logOut,
        googleSignIn
    };

    if (!authCheckComplete) { // Render a loader or null while checking auth state
        return <Loader />;
    }
    return <userContext.Provider value={contextValue}>{children}</userContext.Provider>;
}