import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useUserContext } from '../UserContext';
import { sendEmailVerification } from 'firebase/auth';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signUp, googleSignIn } = useUserContext();
  const [error, setError] = useState(null); // Add error state

  // COMMENTINGTHIS OUT FOR NOW AS PASSING USERNAME AS DISPLAYNAME IN CreatewithUsernameandpassword

//   const updateUsername = async (user, usernameField) => {
//     if (!user) {
//       console.log("No authenticated user found");
//       return;
//     }
//     try {
//       const userDocRef = doc(firestore, "User", user.uid);
      
//       await runTransaction(firestore, async (transaction) => {
//         setUserUsername(usernameField); // Update username in user context
//         const docSnap = await transaction.get(userDocRef);
//         if (!docSnap.exists()) {
//           const newUserObject = {
//             username: usernameField,
//             first_name: '',
//             last_name: '',
//             mobile: '',
//             dob: '',
//             aliases: []
//           };
//           transaction.set(userDocRef, newUserObject);
//         } else {
         
//           const updateObject = { username: usernameField };
//           if (!docSnap.data().roles) {
//             transaction.update(userDocRef, updateObject);
//           }
//         }
//       });
//       console.log("Username and Public Info Updated successfully");
    
//     } catch (error) {
//       console.error("Error updating username:", error);
//     }
// };

  const onSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signUp(email, password); // Pass username as displayName
      console.log('Inside SignUp userCredential.user:', userCredential.user); // Debugging
      // await updateProfile(userCredential.user, { displayName: username });
      // console.log('Going to Create UserDoc'); // Debugging
      // updateUsername(userCredential.user, username);
      await sendEmailVerification(userCredential.user);
      console.log('Verification Email sent'); // Debugging
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      setError(errorMessage); // Set error message in error state
      console.log(errorCode, errorMessage);
    }
  };

  const onGoogleSignUp = async () => {
    try {
      // Sign up with Google using a popup
      await googleSignIn();
    } catch (error) {
      setError(error.message); // Set error message in error state
      console.error('Google Sign-Up Error:', error);
    }
  };


  return (
    <div className="flex flex-col mt-30  md:mt-0  md:flex-row h-screen">
      <div className="hidden md:flex md:flex-1  items-center  md:justify-center "> {/* Image container */}
        <img
          src='/assets/icons/logomark_feg.svg'
          alt="Logo"
          className=" md:h-1/3" />
      </div>

      <div className="  md:flex-1 flex flex-row items-center justify-center p-12"> {/* Form container */}
        <div className=" w-130 space-y-8 items-center justify-center"> {/* Form width */}
          <h2 className="text-3xl  mb-6 color-two-dark  text-left">
            Get your Wealth under control.
          </h2>
          <div className=" w-100 space-y-8"> {/* Form width */}
            <h1 className="text-3xl  mb-6 color-two-dark  text-center">
              Join today.
            </h1>
            {error && (
              <div className="mb-4 text-red-500 text-center">{error}</div>
            )}
            <form className="space-y-6 " onSubmit={onSignup}>
              {/* Username Input */}
              {/* <div>
                <label htmlFor="username" className="block px-4 text-gray-700 text-sm font-medium mb-2">  Username  </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  value={username}
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full px-4 py-2 border rounded-2xl focus:outline-none focus:ring focus:border-blue-500" />
              </div> */}
              <div>
                <label
                  htmlFor="email-address" className="block px-4 text-gray-700 text-sm font-medium mb-2">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  value={email}
                  required
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border rounded-2xl focus:outline-none focus:ring focus:border-blue-500"
                />
              </div>

              <div>
                <label htmlFor="password" className="block px-4 text-gray-700 text-sm font-medium mb-2">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 border rounded-2xl focus:outline-none focus:ring focus:border-blue-500"
                />
              </div>
              <div className="flex justify-center">
                <button
                  type='submit'
                  className="w-full bg-blue-500 text-white rounded-2xl py-2 hover:bg-blue-600"
                >
                  Sign up
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={onGoogleSignUp}
                  className="w-full flex justify-center items-center  border text-black rounded-2xl py-2 hover:bg-gray-300"
                >
                  <img
                    src="/assets/icons/google.svg" // Path to SVG in the public directory
                    alt="Google Icon"
                    style={{ height: '1.5em', width: '1.5em', marginRight: '4px' }}
                  />
                  Sign up with Google
                </button>
              </div>
              <p className='text-gray-600 text-sm mt-4 text-center'>
                Already have an account?{' '}
                <NavLink to='/signin' className='text-blue-500'>
                  Sign in
                </NavLink>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;