import React from 'react'
import { Navigate } from 'react-router-dom'
import { useUserContext } from '../../UserContext';
import Loader from '../Loader';


const RoutesForPublicUsers = ({ children }) => {
  const { currentUser, authCheckComplete, hasApprovedAccess, emailVerified } = useUserContext();

  console.log('RoutesForPublicUsers: currentUser, authCheckComplete, hasApprovedAccess, emailVerified: ', currentUser, authCheckComplete, hasApprovedAccess, emailVerified);
  if (!authCheckComplete   ) return <Loader />
  if (currentUser ) return (hasApprovedAccess && emailVerified) ? <Navigate to="/gym" replace /> : <Navigate to="/approval" replace />;

  return children;

};


export default RoutesForPublicUsers;