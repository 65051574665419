import React from 'react';
import { ReactComponent as LogoLoader } from  '../assets/icons/logomark_feg.svg' ; // Update the path to your SVG file

export default function Loader() {

    return (
        <div className="flex justify-center items-center h-screen">
            <LogoLoader className="pulsate w-18 sm:w-20 md:w-24 lg:w-28" />
        </div>
    )

}