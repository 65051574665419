import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';


const DateSelector = ({ selectedDate, setSelectedDate }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      className='w-40'
      inputFormat="dd/MM/yyyy"
      slotProps={{
        textField: {
          helperText: 'MM/DD/YYYY',
        },
      }}
      value={selectedDate}
      allowSameDateSelection={true}
      onChange={(newValue) => {
        setSelectedDate(newValue);
      }}
      textField={(params) => <TextField {...params} />}
    />
  </LocalizationProvider>
);


export default DateSelector;

