import React, { useState, useEffect } from 'react';
import { useUserContext } from '../UserContext'; // Adjust the import path as necessary
import { getUserProfile, updateUserProfile} from '../services/userAPIServices';
import FieldEditable from './FieldEditable';
import FieldReadOnly from './FieldReadOnly';
import Loader from './Loader';

const UserProfile = () => {
    const [userProfileData, setUserProfileData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { currentUser } = useUserContext();


    const DOMAIN="user";
    const PROFILE_OBJ_REF="usrprfl";
    // const PROFILES_COLLECTION_NAME="user-profiles";
    // const STATUS_OBJ_REF="usrsttus";
    // const STATUSES_COLLECTION_NAME="user-statuses";

    useEffect(() => {
        const fetchUserProfileData = async () => {
            if (currentUser) {
                const userprofileUID = DOMAIN + PROFILE_OBJ_REF + "_" + currentUser.uid;
                try {
                    setIsLoading(true);
                    const profileData = await getUserProfile(userprofileUID);
                    console.log('User Profile data', profileData)
                    setUserProfileData(profileData);
                } catch (err) {
                    setError('Error fetching user profile data: ' + err);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        fetchUserProfileData();
    }, [currentUser]);


    const onFieldSave = async (db_name, newValue) => {
        if (currentUser) {
            const userprofileUID = DOMAIN + PROFILE_OBJ_REF + "_" + currentUser.uid;
            try {
                setIsLoading(true);
                const data = await updateUserProfile(userprofileUID, { [db_name]: newValue });
                setUserProfileData(data);
            } catch (err) {
                setError('Error updating user profile : ' + err);
            } finally {
                setIsLoading(false);
            }
        }
    };


    if (isLoading) return (
        <Loader />
    );
    if (!userProfileData) {
        return (
            <div className="flex justify-center mt-25 p-5">
                <div className="w-full max-w-2xl p-6 bg-white rounded-lg border shadow-md">
                    <h1 className="text-2xl font-bold text-center mb-6">My Profile</h1>
                    <div className="text-red-500 mb-4">No user data found for User UID: {currentUser.uid} </div>
                </div>
            </div>
        );
    };

    const renderField = (db_name, fieldName, value, editable = false) => {
        if (currentUser) {
            // Check if value is undefined
            if (value === undefined) return null;
    
            // Handle array values
            let displayValue = value;
            if (Array.isArray(value) && value.length > 0) {
                displayValue = value.join(', ');
            } else if (Array.isArray(value)) {
                displayValue = ''; // Set to an empty string if the array is empty
            }
            return editable
                ? <FieldEditable db_name={db_name} fieldName={fieldName} value={displayValue} onSave={onFieldSave} />
                : <FieldReadOnly db_name={db_name} fieldName={fieldName} value={displayValue} />;
        }
    };

 

    const formatDate = (dateString) => {
        if (!dateString) return undefined;
        const date = new Date(dateString);
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        };
        const formattedDate = date.toLocaleString('en-AE', options);
        return formattedDate.replace(/,/, '-');
    };



    return (
        <div className="flex justify-center mt-25 p-5">
            <div className="w-full max-w-2xl p-6 bg-white rounded-lg border shadow-md">
                <h1 className="text-2xl font-bold text-center mb-6">My Profile</h1>
                {error && <div className="text-red-500 mb-4">{error}</div>}
                {renderField("email", "Email", userProfileData.email)}
                {renderField("username", "Username", userProfileData.username, true)}
                {renderField("creat_date", "Created On Date", formatDate(userProfileData.creat_date))}
                {renderField("updt_date", "Updated On Date", formatDate(userProfileData.updt_date))}
                {renderField("organizations_ids", "Organizations", userProfileData.organizations_uids )}
                {renderField("uid", "Unique ID", currentUser.uid)}
                {/* {renderField("updt_by_user", "Updated By User", userProfileData.updt_by_user)}
                {renderField("dob", "Date of Birth",  formatDate(userProfileData.dob), true)}
                {renderField("first_name", "First Name", userProfileData.first_name, true)}
                {renderField("last_name", "Last Name", userProfileData.last_name, true)}
                {renderField("mobile", "Mobile", userProfileData.mobile, true)} */}
            </div>
        </div>
    );

};

export default UserProfile;