import React from 'react';

const ChartLegend = () => {
    return (
        <div className="flex justify-center items-center space-x-4 mb-4">
            <div className="flex items-center space-x-1">
                <div className="w-4 h-4 bg-blue-500"></div>
                <span>Price</span>
            </div>
            <div className="flex items-center space-x-1">
                <div className="w-4 h-4 bg-green-500"></div>
                <span>Future Forecast</span>
            </div>
            <div className="flex items-center space-x-1">
                <div className="w-4 h-4 bg-orange-500"></div>
                <span>Train Forecast</span>
            </div>
        </div>
    );
};

export default ChartLegend;
