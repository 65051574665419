import React from 'react';

const FieldReadOnly = ({ fieldName, value }) => {
  const displayValue = value === '' || value === null ? 'Not Available' : value;

  return (
    <div className="flex items-center p-2 border-b border-gray-200">
      <strong className="w-1/3 text-gray-700">{fieldName}:</strong>
      <span className="flex-grow">{displayValue}</span>
    </div>
  );
};

export default FieldReadOnly;