import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useUserContext } from '../UserContext';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { signIn, googleSignIn } = useUserContext();


  const onSignin = async (e) => {
    e.preventDefault();
    try {
      await signIn(email, password);

    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      setError(errorMessage); // Set error message in error state
      console.log(errorCode, errorMessage);
    }
  };

  const onGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="flex flex-col mt-30 md:mt-0  md:flex-row h-screen">
      <div className="hidden md:flex md:flex-1  items-center  md:justify-center "> {/* Image container */}
        <img
          src='/assets/icons/logomark_feg.svg'
          alt="Logo"
          className=" md:h-1/3" />
      </div>


      <div className="   md:flex-1 flex flex-row items-center  justify-center p-12"> {/* Form container */}
        <div className=" w-130 space-y-8 items-center justify-center"> {/* Form width */}
          <h2 className="text-3xl  mb-6 color-two-dark  text-left">
            Back to Wealth Management.
          </h2>
          <div className=" w-100 space-y-8"> {/* Form width */}
            <h1 className="text-3xl  mb-6 color-two-dark  text-center">
              Sign in to pulse.
            </h1>

            {error && (
              <div className="mb-4 text-red-500 text-center">{error}</div>
            )}
            <form className="space-y-6" onSubmit={onSignin}>
              <div>
                <label
                  htmlFor="email-address"
                  className="block px-4 text-gray-700 text-sm font-medium mb-2"
                >
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  required
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border rounded-2xl focus:outline-none focus:ring focus:border-blue-500"
                />
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block px-4 text-gray-700 text-sm font-medium mb-2"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 border rounded-2xl focus:outline-none focus:ring focus:border-blue-500"
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  onSubmit={onSignin}
                  className="w-full bg-blue-500 text-white rounded-2xl py-2 hover:bg-blue-600"
                >
                  Sign in
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={onGoogleSignIn}
                  className="w-full flex justify-center items-center  border text-black rounded-2xl py-2 hover:bg-gray-300"
                >
                  <img
                    src="/assets/icons/google.svg" // Path to SVG in the public directory
                    alt="Google Icon"
                    style={{ height: '1.5em', width: '1.5em', marginRight: '4px' }}
                  />
                  Sign in with Google
                </button>
              </div>
              <p className="text-gray-700 text-sm mt-4 text-center">
                No account yet?{' '}
                <NavLink to="/signup" className="text-blue-500">
                  Sign up
                </NavLink>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
};

export default SignIn;