import { PriceChartComponent } from '../components/Charts/PriceChartComponent'
// import MetricsTable from '../components/ModelMetrics/MetricsTable'
import { firestore } from "../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";

export default function Gym(props) {

  // TODO : THIS IS EXAMPLE ONLY, REMOVE FOR PRODIUCTION, NOT NEEDED FOR LOGIC; THIS SHOULD BE BLOCKED
  // Function to add a message to Firestore
  const addMessage = async () => {
    try {
      const docRef = await addDoc(collection(firestore, "messages"), {
        typedAfterSignIn: "I am here",
        born: 1815
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };



  return (
    <div className=' relative pt-30  '>
      <PriceChartComponent {...props}></PriceChartComponent>

      {/* THIS IS EXAMPLE ONLY, REMOVE FOR PRODIUCTION, NOT NEEDED FOR LOGIC */}
      <button onClick={addMessage} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Add Document
      </button>

      {/* <MetricsTable
        gmoId={'202308a71819e1c67f_gmo'}
        gopId={'202308f1fe3496552b_gop'}
      ></MetricsTable> */}

    </div>
  )
}
